import React from "react"
import { Row, Container, Col, Image } from "react-bootstrap"
function Celebrities(props) {
  return (
    <section id="celebrities" className="section">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col md={5}>
            <h2 className="fw-bold text-uppercase">CELEBRITIES</h2>
            <p className="fw-bold">
              The Hype Santas also made some friends in the celebrity world to
              help spread awareness of their holiday mission:
            </p>
            <p className="fw-bold">[Ja Rule, Shiggy, DJ Irie]</p>
          </Col>
          <Col md={6} className="text-center">
            <Row className="align-items-center">
              <Col>
                <div className="celebrity-person">
                  <Image src={require("assets/images/celebrity-1.png")} fluid />
                  <h6 className="fw-bold text-uppercase mt-3">SHIGGY</h6>
                </div>
              </Col>
              <Col>
                <div className="celebrity-person">
                  <Image src={require("assets/images/celebrity-2.png")} fluid />
                  <h6 className="fw-bold text-uppercase mt-3">Ja RULE</h6>
                </div>
                <div className="celebrity-person mt-5">
                  <Image src={require("assets/images/celebrity-3.png")} fluid />
                  <h6 className="fw-bold text-uppercase mt-3">DJ IRIE</h6>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Celebrities

import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import Slider from "react-slick"
function Prices(props) {
  const settings = {
    dots: false,
    infinite: true,
    pauseOnHover: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    easing: "linear",
  }
  return (
    <section id="prices" className="section">
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md={9} className="text-center">
            <h2 className="fw-bold text-uppercase">PRIZES</h2>
            <p className="fw-bold">
              In true holiday spirit, Hype Santas are giving away gifts and
              prizes to their Discord community members. Join the Discord to
              make some new friends and learn more.
            </p>
          </Col>
        </Row>
        <Slider {...settings}>
          <div className="price-item-slide">
            <div className="item">
              <img src={require("assets/images/price-1.png")} />
              <h3>PAIR OF SNEAKERS</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pulvinar amet ut tincidunt eu pellentesque velit urna. Cras
                quisque malesuada pretium, at.
              </p>
            </div>
          </div>
          <div className="price-item-slide">
            <div className="item">
              <img src={require("assets/images/price-2.png")} />
              <h3>Playstation 5</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pulvinar amet ut tincidunt eu pellentesque velit urna. Cras
                quisque malesuada pretium, at.
              </p>
            </div>
          </div>
          <div className="price-item-slide">
            <div className="item">
              <img src={require("assets/images/price-3.png")} />
              <h3>DOGE puppy</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pulvinar amet ut tincidunt e
              </p>
            </div>
          </div>
          <div className="price-item-slide">
            <div className="item">
              <img src={require("assets/images/price-2.png")} />
              <h3>$5,000 cash</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pulvinar amet ut tincidunt eu pellentesque velit urna. Cras
                quisque malesuada pretium, at.
              </p>
            </div>
          </div>
        </Slider>
      </Container>
    </section>
  )
}
export default Prices

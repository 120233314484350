import React from "react"
import ReactDOM from "react-dom/client" // Updated import
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

// Create a root.
const container = document.getElementById("root")
const root = ReactDOM.createRoot(container) // Use createRoot

// Initial render.
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

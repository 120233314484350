import React from "react";
import { FooterContent, FooterJoinDiscord } from "moleculas";
import { Container } from "react-bootstrap";
function Footer(props) {
  return (
    <>
      <Container>
        <FooterJoinDiscord />
      </Container>
      <footer>
        <FooterContent />
      </footer>
    </>
  );
}
export default Footer;

import React from "react"
import { HeaderNavbar } from "moleculas"
import { Row, Container, Col, Image, Button } from "react-bootstrap"
function Home(props) {
  return (
    <section id="home" className="section">
      <HeaderNavbar />
      <Container className="header-container">
        <Row className="align-items-end header-content mt-lg-3">
          <Col md={6} className="pb-4">
            <h6 className="text-primary text-uppercase">Introducing</h6>
            <h2 className="fw-bold">HYPE SANTAS</h2>
            <p className="fw-bold">
              5,555 Hype Santas went missing from the North Pole. Soon after,
              they were discovered making waves on the Solana blockchain, where
              they set up shop to help families in need this holiday season.
            </p>
            <Button variant="primary" className="text-uppercase fw-bold mb-2">
              JOIN DISCORD
            </Button>
          </Col>
          <Col md={6} className="text-center">
            <Image
              src={require("../../assets/images/HypeSantasGIF.gif")}
              style={{ borderRadius: 36 }}
              width={484}
              fluid
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Home

import React from "react";
import { Row, Col, Button } from "react-bootstrap";
function FooterJoinDiscord(props) {
  return (
    <div className="footer-join-discord">
      <Row>
        <Col md={8}>
          <h2 className="fw-bold text-uppercase">Wanna stay in the know</h2>
          <p className="fw-bold">
            Come join one of the best and friendliest Discord channels in the
            NFT space.
          </p>
          <Button variant="dark" className="text-uppercase fw-bold mt-2">
            JOIN DISCORD
          </Button>
        </Col>
      </Row>
    </div>
  );
}
export default FooterJoinDiscord;

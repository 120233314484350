import { Home, About, Roadmap, Partnership, Prices, Celebrities, Team, Footer } from "organisms";
import "styles/main.scss";
function App() {
  return (
    <>
      <Home/>
      <About/>
      <Roadmap/>
      <Partnership/>
      <Prices/>
      <Celebrities/>
      <Team/>
      <Footer/>
    </>
  );
}

export default App;

import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { ImageSlider } from "moleculas";
function About(props) {
  return (
    <section id="about-us" className="section">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col md={6}>
            <ImageSlider />
          </Col>
          <Col md={5}>
            <h2 className="fw-bold text-uppercase">THE MISSION</h2>
            <p className="fw-bold mt-3">
              The Hype Santas have ditched their sleighs for something even
              faster — Solana. Speed matters because their mission is to help
              bring holiday cheer to as many kids as possible as fast as
              possible.
            </p>
            <p className="fw-bold">
              We’ll be donating a percentage of sales to the Chapman Partnership
              and The Irie Foundation to help improve the lives of children in
              need, at-risk youths, and the homeless.
            </p>
            <p className="fw-bold">
              If you’re down for the cause, then get yourself the most
              dripped-out NFT to ever live on the Solana blockchain.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default About;

import React from "react"
import { Row, Container, Col, Image } from "react-bootstrap"
function Team(props) {
  return (
    <section id="team" className="section">
      <Container>
        <Row className="justify-content-center">
          <Col md={9}>
            <h2 className="text-uppercase fw-bold mb-3">TEAM</h2>
            <p className="fw-bold px-2 mb-5">
              The days of anonymous NFT projects are over. The Hype Santas have
              a team of real people with real business and marketing experience
              who believe Web3 is the future of culture, commerce, and charity.
            </p>
            <Row>
              <Col>
                <div className="team-member">
                  <Image src={require("assets/images/celebrity-1.png")} />
                  <h6 className="fw-bold text-uppercase mt-3">FULL NAME</h6>
                </div>
              </Col>
              <Col>
                <div className="team-member">
                  <Image src={require("assets/images/celebrity-2.png")} />
                  <h6 className="fw-bold text-uppercase mt-3">FULL NAME</h6>
                </div>
              </Col>
              <Col>
                <div className="team-member">
                  <Image src={require("assets/images/celebrity-3.png")} />
                  <h6 className="fw-bold text-uppercase mt-3">FULL NAME</h6>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Team

import React, { useEffect, useState } from "react"
import { RoadmapTimeline } from "moleculas"
import { Container, Row, Col } from "react-bootstrap"
function Roadmap(props) {
  useEffect(() => {
    let santa = document.getElementById("roadmap-santa")
    let window_width = document.body.clientWidth - santa.clientWidth
    let document_height = document.body.clientHeight - window.innerHeight
    window.onscroll = () => {
      var scroll_position = window.pageYOffset
      var object_position_left =
        window_width * (scroll_position / document_height)
      santa.style.left = object_position_left - 100 + "px"
    }
  }, [])
  return (
    <section id="roadmap" className="section">
      <Container className="position-relative">
        <Row className="justify-content-center">
          <Col md={9} className="text-center">
            <h2>ROADMAP</h2>
            <p className="fw-bold mb-5">
              Hype Santas are first and foremost about charity.
            </p>
          </Col>
        </Row>
        <RoadmapTimeline />
      </Container>
      <img
        className="roadmap-santa"
        id="roadmap-santa"
        src={require("assets/images/roadmap-santa.png")}
        width={628}
      />
    </section>
  )
}
export default Roadmap

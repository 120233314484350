import "./timeline.css";

const RoadmapTimeline = () => {
  return (
    <div className="timeline">
      <div className="timeline-container right">
        <div className="timeline-content">
          <h2>On 12/23</h2>
          <p>
            the Hype Santas team will host a food & toy drive in Miami,
            partnering with The Irie Foundation and Chapman Partnership.
          </p>
        </div>
      </div>
      <div className="timeline-container left">
        <div className="timeline-content">
          <h2>At 100% funding</h2>
          <p>
          the Hype Santas will donate a percentage of sales to buy and distribute toys and food to families in need. 
          </p>
          <p>The rest of the funds will be used to develop further real-world initiatives beyond the holiday season.</p>
          <p>
          We’ll reinvest in marketing, promotions and partnerships to continue growing the brand.
          </p>
          <p>
          To reward holders, every Hype Santa owner gets whitelist access to future Hype Section projects and drops.
          </p>
        </div>
      </div>
      <div className="timeline-container right">
        <div className="timeline-content">
          <h2 className="mb-0">FIRST STOP, SOLANA. NEXT STOP…THE MOON</h2>
        </div>
      </div>
    </div>
  );
};

export default RoadmapTimeline;

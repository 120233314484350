import React from "react"
import { Row, Container, Col, Image } from "react-bootstrap"
function Partnership(props) {
  return (
    <section id="partnership" className="section">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col md={5}>
            <h2 className="fw-bold text-uppercase">PARTNERSHIPS</h2>
            <p className="fw-bold">
              During their trip from the North Pole, the Hype Santas made
              friends along the way.
            </p>
            <p className="fw-bold">
              The Doge Pound and Deez Nutz heard about the Santas’ mission and
              wanted to help spread the word and reach a larger audience.
            </p>
          </Col>
          <Col md={6} className="text-center">
            <Image
              src={require("../../assets/images/spaceGIF.gif")}
              width={528}
              fluid
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Partnership

import santa1 from "assets/images/santas/image0.jpeg"
import santa2 from "assets/images/santas/image1.jpeg"
import santa3 from "assets/images/santas/image2.jpeg"
import santa4 from "assets/images/santas/image3.jpeg"
import santa5 from "assets/images/santas/image4.jpeg"
import santa6 from "assets/images/santas/image5.jpeg"
import santa7 from "assets/images/santas/image6.jpeg"
import santa8 from "assets/images/santas/image7.jpeg"
import santa9 from "assets/images/santas/image8.jpeg"
import santa10 from "assets/images/santas/image9.jpeg"
import santa11 from "assets/images/santas/image10.jpeg"
import santa12 from "assets/images/santas/image11.jpeg"

import Slider from "react-slick"
const ImageSlider = () => {
  // const images = [
  //   santa1,
  //   santa2,
  //   santa3,
  //   santa4,
  //   santa5,
  //   santa6,
  //   santa7,
  //   santa8,
  //   santa9,
  //   santa10,
  //   santa11,
  //   santa12,
  // ];
  const settings = {
    dots: false,
    infinite: true,
    vertical: true,
    pauseOnHover: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    easing: "linear",
  }

  const ImageCarousel1 = () => {
    return (
      <Slider {...settings}>
        {[santa1, santa2, santa3, santa4].map((d, i) => {
          return (
            <div key={i} className="item-slide">
              <img
                alt=""
                src={d}
                className="img-fluid"
                style={{ borderRadius: 10 }}
              />
            </div>
          )
        })}
      </Slider>
    )
  }
  const ImageCarousel2 = () => {
    return (
      <Slider {...settings}>
        {[santa5, santa6, santa7, santa8].map((d, i) => {
          return (
            <div key={i} className="item-slide">
              <img
                alt=""
                src={d}
                className="img-fluid"
                style={{ borderRadius: 10 }}
              />
            </div>
          )
        })}
      </Slider>
    )
  }
  const ImageCarousel3 = () => {
    return (
      <Slider {...settings}>
        {[santa9, santa10, santa11, santa12].map((d, i) => {
          return (
            <div key={i} className="item-slide">
              <img
                alt=""
                src={d}
                className="img-fluid"
                style={{ borderRadius: 10 }}
              />
            </div>
          )
        })}
      </Slider>
    )
  }
  return (
    <div className="image-slider-container">
      <div className="row">
        <div className="col">
          <ImageCarousel1 />
        </div>
        <div className="col">
          <ImageCarousel2 />
        </div>
        <div className="col">
          <ImageCarousel3 />
        </div>
      </div>
    </div>
  )
}
export default ImageSlider
